import styled from "styled-components";
import { Box } from "../Grid";
import { themeGet } from "@styled-system/theme-get";

const TriangleFilter = styled(Box)`
  z-index: 2;
  position: absolute !important;
  left: 0;
  bottom: -10vw;
  width: 100%;
  height: 10vw;
  transform: skewY(-4.2deg);
  transform-origin: top left;
  background-image: linear-gradient(
    90deg,
    ${themeGet("colors.EYYellow.default")} 38%,
    ${themeGet("colors.white.100")} 38%
  );
  @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
    background: ${themeGet("colors.EYYellow.default")};
  }
  @media screen and (min-width: 1250px) {
    background-image: linear-gradient(
      90deg,
      ${themeGet("colors.EYYellow.default")} 31.25%,
      ${themeGet("colors.white.100")} 31.25%
    );
  }
  @media screen and (min-width: 1920px) {
    transform: skewY(-3.2deg);
  }
  @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
    background: ${themeGet("colors.EYOffBlack.default")};
  }

  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    height: 10vw;
    width: 100%;
    max-width: 1217px;
    background-image: linear-gradient(
      90deg,
      ${themeGet("colors.EYYellow.default")} 38%,
      ${themeGet("colors.white.100")} 38%
    );

    @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      display: none;
    }

    @media screen and (min-width: 1250px) {
      background-image: linear-gradient(
        90deg,
        ${themeGet("colors.EYYellow.default")} 31.25%,
        ${themeGet("colors.white.100")} 31.25%
      );
    }
  }
`;

TriangleFilter.defaultProps = {
  as: "span",
};

export default TriangleFilter;
