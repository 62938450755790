import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import usePageView from "../utils/usePageView";
import Header from "../components/Header";
import TriangleFilter from "../style/Header/triangleFilter";
import Button from "../components/Button";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";

const NotFoundPage = props => {
  usePageView(props.location.pathname, "404 nenalezeno", "");

  return (
    <Layout location={props.location}>
      <Header
        bgImage={props.data.headerImage}
        containerJustifyContent="flex-end"
        minHeight={["530px", null, "645px", null, null]}
        shadowSimple={true}
      >
        <Heading
          as="h1"
          color="white.100"
          fontSize={[5, null, "40px", "48px", null]}
          fontWeight="light"
          lineHeight={[1, null, "44px", "64px", null]}
          maxWidth={["100%", "400px", "560px", null, null]}
        >
          Stránku jsme nenašli
        </Heading>
        <Text
          color="white.100"
          fontSize="2"
          fontWeight="light"
          maxWidth={["auto", null, "400px", null, null]}
          mb={[4, 5, null, 6, null]}
          mt={[3, 3, null, 4, null]}
        >
          Mrzí nás to, ale tahle stránka neexistuje. Na cestu s EY naskočíš zase přes úvodní stránku.
            <Button href={"/"} mt={[3, 3, null, 4, null]}>
                Zpět na úvodní stránku
            </Button>
        </Text>
      </Header>
      <SEO title="404: Stránka nenalezena" />
    </Layout>
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    headerImage: file(relativePath: {eq: "jobBg.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 90)
      }
    }
  }
`;

export default NotFoundPage;
